import React from 'react';
import Section from './Section';
import { Col, Row, Space } from '../common/layout';
import { Heading } from '../common/typography';
import Button from '../common/Button';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import { url } from '../../constant/url';
import Link from 'next/link';
import { CardPlain } from '../common/card';
import useAuth from '../../libs/hooks/useAuth';

const SectionCTA = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { authenticated } = useAuth();

  return (
    <Section>
      <Row align={'middle'} justify={'center'}>
        <Col span={24}>
          <CardPlain paddingSize={['xxl', 'xl']} backgroundColor={'primary'}>
            <Space direction={'vertical'} align={'center'} size={'lg'} full>
              <div>
                <Heading color={'white'} level={2} align={'center'}>
                  {t('landing:cta.title')}
                </Heading>
                <Heading color={'white'} level={5} align={'center'}>
                  {authenticated
                    ? t('landing:cta.loggedInSubtitle')
                    : t('landing:cta.subtitle')}
                </Heading>
              </div>
              {authenticated ? (
                <Link passHref href={url.gacha_market}>
                  <Button type={'primaryOutline'}>
                    {t('landing:PlayNow')}
                  </Button>
                </Link>
              ) : (
                <Link passHref href={url.register}>
                  <Button type={'primaryOutline'}>{t('register')}</Button>
                </Link>
              )}
            </Space>
          </CardPlain>
        </Col>
      </Row>
    </Section>
  );
};

export default SectionCTA;
