import React from 'react';
import Section from './Section';
import { Col, Row, Space } from '../common/layout';
import Image from 'next/legacy/image';
import Steps from '../common/steps';
import { Heading } from '../common/typography';
import LearnMore from '../common/learn-more';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { gitbookUrl } from '../../constant/url';

export const StyledImage = styled.div({
  maxWidth: 380,
  margin: 'auto',
  [media.lg]: {
    maxWidth: 520,
  },
});

const SectionBorrowNft = () => {
  const { t, i18n } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <Section>
      <Row gutterSize={'xxl'} align={'middle'} justify={'center'}>
        <Col span={20} md={12}>
          <StyledImage>
            <Image
              width={1091}
              height={700}
              src={'/images/landing/borrow-pig.png'}
              layout={'responsive'}
              alt={'Candy Pig'}
            />
          </StyledImage>
        </Col>
        <Col span={20} md={12}>
          <Space
            direction={'vertical'}
            align={'center'}
            size={'xl'}
            md={{
              align: 'start',
            }}
          >
            <div>
              <Heading
                align={'center'}
                md={{ align: 'start' }}
                color={'primary'}
                level={2}
              >
                {t('landing:borrowNft.title')}
              </Heading>
              <Heading align={'center'} md={{ align: 'start' }} level={5}>
                {t('landing:borrowNft.subtitle')}
              </Heading>
            </div>
            <Steps
              current={2}
              items={[
                {
                  title: t('landing:borrowNft.content.0.title'),
                  description: t('landing:borrowNft.content.0.desc'),
                  status: 'process',
                },
                {
                  title: t('landing:borrowNft.content.1.title'),
                  description: t('landing:borrowNft.content.1.desc'),
                  status: 'process',
                },
                {
                  title: t('landing:borrowNft.content.2.title'),
                  description: t('landing:borrowNft.content.2.desc'),
                  status: 'process',
                },
              ]}
            />
            <LearnMore url={gitbookUrl.lending[i18n.language]} />
          </Space>
        </Col>
      </Row>
    </Section>
  );
};

export default SectionBorrowNft;
