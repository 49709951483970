import React from 'react';
import Section from './Section';
import { Col, Row, Space } from '../common/layout';
import { Heading, Paragraph } from '../common/typography';
import { gitbookUrl } from '../../constant/url';
import LearnMore from '../common/learn-more';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { themeMode } from '../../constant/themes';
import { useThemeMode } from '../../libs/hooks/useThemeMode';

import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

export const StyledVideo = styled.div({
  maxWidth: 300,
  margin: 'auto',
  position: 'relative',
  [media.lg]: {
    maxWidth: 500,
  },
});

const SectionNFTPurpose = () => {
  const { t, i18n } = useTranslation(commonConfig.i18nNamespaces);
  const { selectedThemeMode } = useThemeMode();

  const videoUrl =
    selectedThemeMode === themeMode.light
      ? '/videos/purpose-nft.mp4'
      : '/videos/purpose-nft-dark.mp4';

  return (
    <Section>
      <Row gutterSize={'xxl'} align={'middle'} justify={'center'}>
        <Col span={24} order={2} md={{ span: 14, order: 1 }}>
          <Space
            direction={'vertical'}
            size={'lg'}
            align={'center'}
            md={{
              align: 'start',
            }}
          >
            <Heading
              align={'center'}
              md={{ align: 'start' }}
              color={'primary'}
              level={2}
            >
              {t('landing:purpose.title')}
            </Heading>
            <div>
              <Paragraph>{t('landing:purpose.content.0')}</Paragraph>
              <Paragraph>{t('landing:purpose.content.1')}</Paragraph>
              <Paragraph>{t('landing:purpose.content.2')}</Paragraph>
              <Paragraph>{t('landing:purpose.content.3')}</Paragraph>
            </div>
            <LearnMore url={gitbookUrl.nftPurpose[i18n.language]} />
          </Space>
        </Col>
        <Col span={20} order={1} md={{ span: 10, order: 2 }}>
          <StyledVideo>
            <ReactPlayer
              url={videoUrl}
              muted
              loop
              playing
              width="100%"
              height="100%"
              playsinline
            />
          </StyledVideo>
        </Col>
      </Row>
    </Section>
  );
};

export default SectionNFTPurpose;
