import type {
  CollapsePanelProps as AntdCollapsePanelProps,
  CollapseProps as AntdCollapseProps,
} from 'antd';
import { Collapse as AntdCollapse } from 'antd';
import type { FC } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { IconCheveron } from './icon';

const StyledCollapsePanel = styled(AntdCollapse.Panel, {
  shouldForwardProp: (propName) => !['margin'].includes(propName),
})<AntdCollapsePanelProps>(({ theme }) => ({
  ['&.ant-collapse-item, &.ant-collapse-item:last-child']: {
    border: `1px solid ${theme.token.colorBorder}`,
    borderRadius: theme.token.borderRadiusXS,
    marginBottom: theme.token.sizeMD,
    boxShadow: theme.token.boxShadowTertiary,
  },
  ['&.ant-collapse-item:last-child']: {
    marginBottom: 0,
  },
  ['&.ant-collapse-item.ant-collapse-item-active']: {
    border: `1px solid ${theme.token.colorPrimary}`,
  },
  ['&.ant-collapse-item .ant-collapse-header-text']: {
    color: theme.token.colorText,
    fontStyle: 'italic',
  },
}));

type CollapseItem = {
  title: string;
  description: string;
};

type CollapseProps = AntdCollapseProps & {
  items: CollapseItem[];
};

const Collapse: FC<CollapseProps> = ({ items }) => {
  return (
    <>
      <AntdCollapse
        bordered={false}
        defaultActiveKey={['0']}
        expandIconPosition={'end'}
        expandIcon={({ isActive }) =>
          isActive ? (
            <IconCheveron size={24} mode="up" />
          ) : (
            <IconCheveron size={24} mode="down" />
          )
        }
      >
        {items.map((qa, index) => (
          <StyledCollapsePanel header={qa.title} key={index}>
            <p>{qa.description}</p>
          </StyledCollapsePanel>
        ))}
      </AntdCollapse>
    </>
  );
};

export default Collapse;
