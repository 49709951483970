import type { FC, ReactNode } from 'react';
import React from 'react';
import type { CardProps } from 'antd';
import { Card as AntdCard } from 'antd';
import styled from '@emotion/styled';
import { Heading } from '../typography';
import Loading from '../loading';
import type { TypographyColor } from '../typography/useTypographyColor';
import { getTypographyColor } from '../typography/useTypographyColor';
import type { SizeType } from '../layout/useSize';
import { getSize } from '../layout/useSize';
import { media } from '@candypig-monorepo/types';

const StyledCard = styled(AntdCard, {
  shouldForwardProp: (propName) =>
    !['margin', 'backgroundColor', 'paddingSize', 'full'].includes(propName),
})<CardPlainProps>(({ theme, backgroundColor, paddingSize }) => ({
  overflow: 'hidden',
  ['&>.ant-card-body, &>.ant-card-actions']: {
    background:
      backgroundColor === 'plain'
        ? theme.components.Card.colorBgContainerPlain
        : backgroundColor && getTypographyColor(theme, backgroundColor),
  },
  ['&>.ant-card-body']: {
    padding: getSize(theme, 'lg'),
    [media.sm]: {
      padding: getSize(theme, 'xl'),
    },
    [media.md]: {
      padding: getSize(theme, 'xl'),
    },
    [media.lg]: {
      padding: getSize(theme, 'xl'),
    },
    [media.xl]: {
      padding: getSize(theme, paddingSize ?? 'xxl'),
    },
  },
}));

export type CardPlainProps = CardProps & {
  paddingSize?: SizeType | [SizeType, SizeType];
  heading?: string;
  children: ReactNode;
  isLoading?: boolean;
  backgroundColor?: TypographyColor | 'plain';
};
const CardPlain: FC<CardPlainProps> = ({
  heading,
  isLoading,
  children,
  ...props
}) => {
  return (
    <StyledCard bordered={false} {...props}>
      {heading && <Heading level={5}>{heading}</Heading>}
      {isLoading && <Loading />}
      {!isLoading && children}
    </StyledCard>
  );
};

export default CardPlain;
