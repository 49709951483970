import React from 'react';
import Section from './Section';
import { Col, Row, Space } from '../common/layout';
import Image from 'next/legacy/image';
import { Heading } from '../common/typography';
import LearnMore from '../common/learn-more';
import { gitbookUrl } from '../../constant/url';
import Collapse from '../common/collapse';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { keyframes } from '@emotion/react';

//TODO: figure out a way to get length of locale json
const QUESTION_LENGTH = 5;
const questions = new Array(QUESTION_LENGTH).fill(1).map((_, i) => ({
  title: `landing:questions.questionsBank.${i}.title`,
  desc: `landing:questions.questionsBank.${i}.desc`,
}));

const moveUpMore = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(40px);
  }
`;

const moveUp = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
`;

export const StyledPinkQuestion = styled('div')(({ theme }) => ({
  position: 'absolute',
  animation: `${moveUpMore} 2300ms ease-in-out infinite alternate both`,
  left: 'calc(50% - 170px)',
  width: 190,
  top: 20,
  color: theme.token.colorText,
  [media.sm]: {
    left: 'calc(50% - 220px)',
    width: 240,
    top: 60,
  },
  [media.md]: {
    left: 'calc(50% - 200px)',
    width: 240,
    top: 20,
  },
  [media.lg]: {
    left: 'calc(50% - 240px)',
    width: 280,
  },
}));

export const StyledWhiteQuestion = styled('div')(({ theme }) => ({
  position: 'absolute',
  animation: `${moveUp} 2300ms ease-in-out infinite alternate both`,
  color: theme.token.colorText,
  right: 'calc(50% - 150px)',
  width: 170,
  top: 20,
  [media.sm]: {
    right: 'calc(50% - 200px)',
    width: 220,
    top: 60,
  },
  [media.md]: {
    right: 'calc(50% - 200px)',
    width: 220,
    top: 20,
  },
  [media.lg]: {
    right: 'calc(50% - 240px)',
    width: 260,
  },
}));

const SectionQuestions = () => {
  const { t, i18n } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <Section>
      <Row gutterSize={'xxl'} align={'middle'} justify={'center'}>
        <Col span={24} order={2} md={{ span: 12, order: 1 }}>
          <Space
            direction={'vertical'}
            size={'xl'}
            full
            align={'center'}
            md={{
              align: 'start',
            }}
          >
            <div>
              <Heading
                align={'center'}
                md={{ align: 'start' }}
                color={'primary'}
                level={2}
              >
                {t('landing:questions.title')}
              </Heading>
              <Heading align={'center'} md={{ align: 'start' }} level={5}>
                {t('landing:questions.subtitle')}
              </Heading>
            </div>
            <Collapse
              items={questions.map((step) => ({
                ...step,
                title: t(step.title),
                description: t(step.desc),
              }))}
            />
            <LearnMore url={gitbookUrl.guide[i18n.language]} />
          </Space>
        </Col>
        <Col span={18} order={1} md={{ span: 12, order: 2 }}>
          <Image
            width={767}
            height={560}
            src={'/images/landing/qna/duo-questions.png'}
            layout={'responsive'}
            alt={'Candy Pig'}
            style={{ opacity: 0 }}
          />
          <StyledWhiteQuestion>
            <Image
              width={315}
              height={280}
              src={'/images/landing/qna/white-question.png'}
              layout={'responsive'}
              alt={'Candy Pig'}
            />
          </StyledWhiteQuestion>
          <StyledPinkQuestion>
            <Image
              width={376}
              height={331}
              src={'/images/landing/qna/pink-question.png'}
              layout={'responsive'}
              alt={'Candy Pig'}
            />
          </StyledPinkQuestion>
        </Col>
      </Row>
    </Section>
  );
};

export default SectionQuestions;
