import React from 'react';
import Section from './Section';
import { Col, Row, Space } from '../common/layout';
import Image from 'next/legacy/image';
import { Heading } from '../common/typography';
import Button from '../common/Button';
import AvatarList from '../common/avatar-list';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import { url } from '../../constant/url';
import Link from 'next/link';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { keyframes } from '@emotion/react';

const stepsContent = [
  {
    avatar: 'star_card.png',
    title: 'landing:startingSteps.steps.0.title',
    desc: 'landing:startingSteps.steps.0.desc',
  },
  {
    avatar: 'coin-approved.png',
    title: 'landing:startingSteps.steps.1.title',
    desc: 'landing:startingSteps.steps.1.desc',
  },
  {
    avatar: 'egg-pig.png',
    title: 'landing:startingSteps.steps.2.title',
    desc: 'landing:startingSteps.steps.2.desc',
  },
];

const slideBL = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateY(10px) translateX(-10px);
  }
`;

const slideBR = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateY(10px) translateX(10px);
  }
`;

const scaleUp = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.3;
  }
`;

export const StyledPinkPiggie = styled.div({
  maxWidth: 300,
  margin: 'auto',
  [media.lg]: {
    maxWidth: 400,
  },
});

export const StyledYellowCandy = styled.div({
  position: 'absolute',
  maxWidth: 180,
  animation: `${slideBL} 2300ms ease-in-out infinite alternate both`,
  width: 80,
  top: 100,
  right: 'calc(50% - 190px)',
  [media.sm]: {
    top: 100,
    right: 'calc(50% - 190px)',
  },
  [media.md]: {
    top: 110,
    right: 'calc(50% - 190px)',
  },
  [media.lg]: {
    top: 130,
    right: 'calc(50% - 250px)',
    width: 90,
  },
});

export const StyledBlueCandy = styled.div({
  position: 'absolute',
  maxWidth: 180,
  animation: `${slideBR} 3500ms ease-in-out infinite alternate both`,
  width: 80,
  top: 180,
  left: 'calc(50% - 190px)',
  [media.sm]: {
    top: 180,
    left: 'calc(50% - 190px)',
  },
  [media.md]: {
    top: 180,
    left: 'calc(50% - 180px)',
  },
  [media.lg]: {
    top: 230,
    left: 'calc(50% - 260px)',
    width: 90,
  },
});

export const StyledOrangeCandy = styled.div({
  position: 'absolute',
  maxWidth: 180,
  animation: `${slideBR} 3000ms ease-in-out 1500ms infinite alternate both`,
  top: 100,
  left: 'calc(50% - 190px)',
  width: 60,
  [media.sm]: {
    top: 100,
    left: 'calc(50% - 190px)',
  },
  [media.md]: {
    top: 100,
    left: 'calc(50% - 190px)',
  },
  [media.lg]: {
    top: 130,
    left: 'calc(50% - 230px)',
    width: 70,
  },
});

export const StyledStar = styled.div({
  position: 'absolute',
  maxWidth: 180,
  animation: `${scaleUp} 3500ms ease-in-out 1500ms infinite alternate both`,
  top: 'calc(50% - 150px)',
  left: 'calc(50% - 170px)',
  width: 70,
  [media.sm]: {
    top: 30,
    left: 'calc(50% - 150px)',
  },
  [media.md]: {
    top: 30,
    left: 'calc(50% - 150px)',
  },
  [media.lg]: {
    top: 30,
    left: 'calc(50% - 210px)',
    width: 70,
  },
});

const SectionStartingSteps = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <Section>
      <Row gutterSize={'xxl'} align={'middle'} justify={'center'}>
        <Col span={20} md={10} lg={12}>
          <StyledYellowCandy>
            <Image
              width={193}
              height={185}
              src={'/images/landing/steps/candy-right.png'}
              alt={'Candy Pig'}
            />
          </StyledYellowCandy>
          <StyledBlueCandy>
            <Image
              width={145}
              height={137}
              src={'/images/landing/steps/blue-candy.png'}
              alt={'Candy Pig'}
            />
          </StyledBlueCandy>
          <StyledOrangeCandy>
            <Image
              width={110}
              height={110}
              src={'/images/landing/steps/orange-candy.png'}
              alt={'Candy Pig'}
            />
          </StyledOrangeCandy>
          <StyledPinkPiggie>
            <Image
              width={664}
              height={700}
              src={'/images/landing/steps/pink-piggie.png'}
              alt={'Candy Pig'}
            />
          </StyledPinkPiggie>
          <StyledStar>
            <Image
              width={96}
              height={105}
              src={'/images/landing/steps/yellow-star.png'}
              alt={'Candy Pig'}
            />
          </StyledStar>
        </Col>
        <Col span={22} md={14} lg={12}>
          <Space direction={'vertical'} size={'xl'}>
            <Heading
              align={'center'}
              md={{ align: 'start' }}
              level={2}
              color={'primary'}
            >
              {t('landing:startingSteps.title')}
            </Heading>
            <AvatarList
              dataSource={stepsContent.map((step) => ({
                ...step,
                title: t(step.title),
                description: t(step.desc),
              }))}
            />
            <Space
              full
              justify={'center'}
              lg={{
                justify: 'start',
              }}
            >
              <Link href={url.register}>
                <Button type={'primary'}>{t('register')}</Button>
              </Link>
            </Space>
          </Space>
        </Col>
      </Row>
    </Section>
  );
};

export default SectionStartingSteps;
