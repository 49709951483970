import type { ReactNode } from 'react';
import React from 'react';
import Section from './Section';
import { Col, Row, Space } from '../common/layout';
import Image from 'next/legacy/image';
import { Heading, Link, Paragraph, Text } from '../common/typography';
import { CardPlain } from '../common/card';
import Button from '../common/Button';
import LearnMore from '../common/learn-more';
import { gitbookUrl, url } from '../../constant/url';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import { media } from '@candypig-monorepo/types';

const StyledCol = styled(Col)(({ theme }) => ({
  ['&::after']: {
    display: 'inline-block',
    backgroundImage: "url('/images/landing/pink-arrow.png')",
    backgroundSize: '24px 24px',
    width: 24,
    height: 24,
    content: '""',
    position: 'absolute',
  },
  ['&:nth-of-type(1)::after']: {
    transform: 'rotate(0deg)',
    right: -16,
    top: 'calc(50% - 18px)',
  },
  ['&:nth-of-type(2)::after, &:nth-of-type(3)::after']: {
    transform: 'rotate(90deg)',
    bottom: -36,
    right: 'calc(50% - 18px)',
  },
  ['&:nth-of-type(2)']: {
    [media.sm_max]: {
      ['.ant-card']: {
        paddingTop: '24px',
      },
    },
  },
  ['&:nth-of-type(1)::before']: {
    backgroundImage: "url('/images/landing/pink-arrow.png')",
    backgroundSize: '24px 24px',
    transform: 'rotate(270deg)',
    display: 'inline-block',
    width: 24,
    height: 24,
    content: '""',
    position: 'absolute',
    top: 'calc(100% + 12px)',
    right: 'calc(50% - 18px)',
  },
  ['&:nth-of-type(5)::after']: {
    display: 'none',
  },
  ['&:nth-of-type(4)::after']: {
    backgroundImage: 'none',
    transform: 'rotate(0deg)',
    right: 'calc(50% - 50px)',
    backgroundColor: theme.token.colorPrimary,
    width: 100,
    height: 5,
    top: 'calc(100% + 20px)',
  },
  ['&:nth-of-type(6)::after']: {
    transform: 'rotate(180deg)',
    left: -12,
    top: 'calc(50% - 18px)',
  },
  [media.md]: {
    ['&::after']: {
      display: 'inline-block',
      backgroundImage: "url('/images/landing/pink-arrow.png')",
      backgroundSize: '36px 36px',
      width: 36,
      height: 36,
      content: '""',
      position: 'absolute',
    },
    ['&:nth-of-type(1)::after, &:nth-of-type(2)::after']: {
      transform: 'rotate(0deg)',
      top: 'calc(50% - 18px)',
      right: -20,
    },
    ['&:nth-of-type(3)::after']: {
      transform: 'rotate(90deg)',
      top: 'calc(100% + 8px)',
      right: 'calc(50% - 18px)',
    },
    ['&:nth-of-type(4)::after']: {
      backgroundImage: 'none',
      transform: 'rotate(0deg)',
      left: 'calc(100% + -4px)',
      right: 'unset',
      backgroundColor: theme.token.colorPrimary,
      width: 8,
      height: 100,
      top: 'calc(100% + -180px)',
      display: 'inline-block',
    },
    ['&:nth-of-type(5)::after']: {
      display: 'none',
    },
    ['&:nth-of-type(6)::after']: {
      transform: 'rotate(180deg)',
      top: 'calc(50% - 18px)',
      left: -20,
    },
    ['&:nth-of-type(1)::before']: {
      backgroundImage: "url('/images/landing/pink-arrow.png')",
      backgroundSize: '36px 36px',
      transform: 'rotate(270deg)',
      display: 'inline-block',
      width: 36,
      height: 36,
      content: '""',
      position: 'absolute',
      top: 'calc(100% + 8px)',
      right: 'calc(50% - 18px)',
    },
  },
}));

const StyledTopBar = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  color: theme.token.colorWhite,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ['p']: {
    marginLeft: theme.token.sizeXS,
  },
}));

const StyledImageBox = styled('div')(() => ({
  width: 80,
  [media.lg]: {
    width: 100,
  },
}));

const StyledIconBox = styled('div')(({ theme }) => ({
  width: theme.token.sizeMD,
  height: theme.token.sizeMD,
  display: 'flex',
  alignItems: 'center',
  [media.lg]: {
    width: theme.token.sizeLG,
    height: theme.token.sizeLG,
  },
}));

const StyledCardPlain = styled(CardPlain)(({ theme }) => ({
  height: '100%',
  ['.ant-card-head']: {
    padding: `${theme.token.sizeSM}px`,
    background: theme.token.colorPrimary,
    color: theme.token.colorWhite,
    border: 0,
    minHeight: 'fit-content',
    height: theme.token.sizeXL,
    [media.lg]: {
      height: 40,
    },
  },
  ['.ant-card-body']: {
    padding: `${theme.token.sizeXL}px ${theme.token.sizeMD}px`,
    [media.sm]: {
      padding: `40px ${theme.token.sizeMD}px`,
    },
    [media.sm_max]: {
      padding: `${theme.token.sizeSM}px 8px`,
    },
  },
  ['.ant-card-head+.ant-card-body']: {
    paddingTop: theme.token.sizeXXS,
  },
}));

type StepCard = {
  icon: string;
  title: string;
  desc: string;
  mobileOrder: number;
  topBorder?: ReactNode;
};

const StyledParagraph = styled(Paragraph)(({ theme }) => ({
  lineHeight: 1.25,
  fontSize: theme.token.fontSize,
  [media.sm_max]: {
    fontSize: theme.token.fontSizeSM,
  },
}));

//TODO: refactor this array
const cards: StepCard[] = [
  {
    icon: 'nft-scope-1.png',
    title: 'landing:gachaRaffle.cards.0.title',
    desc: 'landing:gachaRaffle.cards.0.desc',
    topBorder: 'landing:gachaRaffle.cards.0.topBorder',
    mobileOrder: 1,
  },
  {
    icon: 'bid-pig.png',
    title: 'landing:gachaRaffle.cards.1.title',
    desc: 'landing:gachaRaffle.cards.1.desc',
    mobileOrder: 2,
  },
  {
    icon: 'clock.png',
    title: 'landing:gachaRaffle.cards.2.title',
    desc: 'landing:gachaRaffle.cards.2.desc',
    mobileOrder: 4,
  },
  {
    icon: 're-raffle.png',
    title: 'landing:gachaRaffle.cards.3.title',
    desc: 'landing:gachaRaffle.cards.3.desc',
    mobileOrder: 3,
  },
  {
    icon: 'collect-pig.png',
    title: 'landing:gachaRaffle.cards.4.title',
    desc: 'landing:gachaRaffle.cards.4.desc',
    mobileOrder: 5,
  },
  {
    icon: 'win-pig.png',
    title: 'landing:gachaRaffle.cards.5.title',
    desc: 'landing:gachaRaffle.cards.5.desc',
    mobileOrder: 6,
  },
];

const SectionGachaRaffle = () => {
  const { t, i18n } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <Section>
      <Row gutterSize={'xl'} align={'middle'} justify={'center'}>
        <Col span={24}>
          <Heading level={2} color={'primary'} align={'center'}>
            {t('landing:gachaRaffle.title')}
          </Heading>
          <Heading level={5} align={'center'}>
            {t('landing:gachaRaffle.subtitle')}
          </Heading>
        </Col>
        <Col span={24}>
          <Row
            gutter={[
              {
                xs: 48,
                sm: 32,
                md: 56,
              },
              {
                xs: 48,
                sm: 48,
                md: 56,
              },
            ]}
            align={'stretch'}
          >
            {cards.map((card, index) => (
              <StyledCol
                key={card.title}
                xs={{
                  span: 12,
                  offset: 0,
                  order: card.mobileOrder,
                }}
                md={{
                  span: 8,
                  offset: 0,
                  order: index,
                }}
              >
                <StyledCardPlain
                  title={
                    card.topBorder && (
                      <StyledTopBar>
                        <StyledIconBox>
                          <Image
                            src="/images/ico_arrow_right_o.svg"
                            width={24}
                            height={24}
                            alt="ico_arrow_right"
                          />
                        </StyledIconBox>
                        <Text color={'white'}>
                          {t(card.topBorder as string)}
                        </Text>
                      </StyledTopBar>
                    )
                  }
                >
                  <Space
                    direction={'vertical'}
                    size={'xxs'}
                    full
                    align={'center'}
                  >
                    <StyledImageBox>
                      <Image
                        width={100}
                        height={100}
                        src={`/images/landing/${card.icon}`}
                        alt={''}
                      />
                    </StyledImageBox>
                    <Text size={'lg'}>{t(card.title)}</Text>
                    <StyledParagraph align={'center'} size={'sm'}>
                      {t(card.desc)}
                    </StyledParagraph>
                  </Space>
                </StyledCardPlain>
              </StyledCol>
            ))}
          </Row>
        </Col>
        <Col span={20}>
          <Space full justify={'center'} align={'center'} size={'large'}>
            <Link href={url.gacha_market}>
              <Button type={'primary'}>{t('landing:PlayNow')}</Button>
            </Link>
            <LearnMore url={gitbookUrl.gachaRaffle[i18n.language]} />
          </Space>
        </Col>
      </Row>
    </Section>
  );
};

export default SectionGachaRaffle;
