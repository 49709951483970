import styled from '@emotion/styled';
import type { StepsProps as AntdStepsProps } from 'antd';
import { Steps as AntdSteps } from 'antd';
import type { FC } from 'react';

type StepsProps = AntdStepsProps & {
  size?: number;
};

const StyledSteps = styled(AntdSteps, {
  shouldForwardProp: (propName) => propName !== 'size',
})<StepsProps>((props) => ({
  textAlign: 'left',
  ['.ant-steps-item-process>.ant-steps-item-container']: {
    ['&>.ant-steps-item-tail::after']: {
      backgroundColor: 'transparent',
      border: `1px dashed ${props.theme.token.colorPrimary}`,
    },
    ['&>.ant-steps-item-content>.ant-steps-item-title']: {
      lineHeight: 1.2,
      fontWeight: 600,
      color: props.theme.token.colorTextBase,
      fontSize: 16,
    },
    ['&>.ant-steps-item-content>.ant-steps-item-description']: {
      paddingBottom: props.theme.token.sizeXL,
    },
  },
  ['.ant-steps-item-process:last-child>.ant-steps-item-container']: {
    ['&>.ant-steps-item-content>.ant-steps-item-description']: {
      paddingBottom: 0,
    },
  },
}));

const Steps: FC<StepsProps> = ({ items, direction = 'vertical' }) => {
  return <StyledSteps direction={direction} items={items} />;
};

export default Steps;
