import styled from '@emotion/styled';
import type { ListProps as AntdListProps } from 'antd';
import { List as AntdList } from 'antd';
import type { FC } from 'react';
import React from 'react';
import Image from 'next/legacy/image';

type AvatarListProps = AntdListProps<AvatarListItem>;

type AvatarListItem = {
  avatar: string;
  title: string;
  description: string;
};

const { Item } = AntdList;

const StyledList = styled(AntdList, {
  shouldForwardProp: (propName) => propName !== 'size',
})<AvatarListProps>(({ theme }) => {
  return {
    textAlign: 'left',
    ['&.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title']:
      {
        fontFamily: "'MontserratVariable', sans-serif",
        color: `${theme.token.colorText}!important`,
        fontVariationSettings: '"wght" 600',
        fontWeight: '600!important',
        fontSize: 16,
      },
    ['&.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description']:
      {
        color: `${theme.token.colorText}!important`,
        fontSize: 16,
      },
  };
});

const AvatarList: FC<AvatarListProps> = ({ itemLayout, dataSource }) => {
  return (
    <StyledList
      itemLayout={itemLayout}
      dataSource={dataSource}
      renderItem={(item, index) => (
        <Item key={index}>
          <Item.Meta
            avatar={
              <Image
                width={40}
                height={40}
                src={`/images/landing/${item.avatar}`}
                alt={item.title}
              />
            }
            title={item.title}
            description={item.description}
          />
        </Item>
      )}
    />
  );
};

export default AvatarList;
