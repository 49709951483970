import React from 'react';
import { useTranslation } from 'next-i18next';
import Section from './Section';
import { Col, Row, Space } from '../common/layout';
import Image from 'next/legacy/image';
import { Heading, Paragraph } from '../common/typography';
import Button from '../common/Button';
import { Typography } from 'antd';
import { gitbookUrl, url } from '../../constant/url';
import LearnMore from '../common/learn-more';
import Link from 'next/link';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { media } from '@candypig-monorepo/types';
import { useTheme } from '../../libs/hooks/useTheme';
import { useThemeMode } from '../../libs/hooks/useThemeMode';
import { themeMode } from '../../constant/themes';
import FirstWordBold from '../common/first-word-bold';

const slideLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50px);
  }
`;

export const StyledImage = styled.div({
  maxWidth: 600,
  margin: 'auto',
  [media.lg]: {
    maxWidth: 'none',
  },
});

const PlaceHolder = styled.div({
  height: '340px',
  [media.sm]: {
    height: '320px',
  },
  [media.lg]: {
    height: 0,
  },
});

const StyledLeftCloud = styled('div')({
  position: 'absolute',
  top: '15%',
  left: -100,
  width: 100,
  animation: `${slideLeft} 4800ms cubic-bezier(0.445, 0.050, 0.550, 0.950) infinite alternate both`,
  [media.lg]: {
    top: '40%',
    width: 200,
  },
});

const StyledRightCloud = styled('div')({
  position: 'absolute',
  top: '5%',
  right: -120,
  width: 160,
  animation: `${slideLeft} 5800ms cubic-bezier(0.445, 0.050, 0.550, 0.950) 800ms infinite alternate both`,
  [media.lg]: {
    top: '10%',
    width: 260,
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  ['&.ant-typography > blockquote']: {
    borderInlineStart: `4px solid ${theme.token.colorPrimary}`,
  },
}));

const StyledHeading = styled(Heading)(({ theme }) => ({
  ['&.ant-typography']: {
    [media.md]: {
      marginTop: theme.token.sizeXL,
    },
  },
}));

const StyledSection = styled(Section)(({ theme }) => ({
  ['&&']: {
    margin: `-${theme.token.sizeLG}px auto ${theme.token.sizeLG}px auto`,
    [media.sm]: {
      margin: `-${theme.token.sizeXS}px auto ${theme.token.sizeXXL}px auto`,
    },
  },
}));

const SectionIntroduction = () => {
  const { t, i18n } = useTranslation(['common', 'landing']);
  const { selectedThemeMode } = useThemeMode();

  const { sizeLG, sizeXL } = useTheme();

  return (
    <StyledSection>
      <StyledLeftCloud>
        <Image
          src={'/images/landing/introduction/cloud-left-1.png'}
          width={469}
          height={295}
          alt=""
        />
      </StyledLeftCloud>
      <StyledRightCloud>
        <Image
          src={'/images/landing/introduction/cloud-right-2.png'}
          width={476}
          height={343}
          alt=""
        />
      </StyledRightCloud>
      <Row
        gutter={{
          xs: sizeLG,
          md: sizeXL,
          lg: sizeXL,
        }}
        align={'middle'}
        justify={'center'}
      >
        <Col span={24} order={2} md={{ span: 14, order: 1 }}>
          <Space
            full
            direction={'vertical'}
            size={[0, 'md']}
            align={'center'}
            md={{
              align: 'start',
            }}
          >
            <div>
              <StyledHeading
                color={'primary'}
                level={1}
                align={'center'}
                md={{ align: 'start' }}
              >
                {t('landing:introduction.title')}
              </StyledHeading>
              <Heading
                weight={600}
                align={'center'}
                md={{ align: 'start' }}
                level={4}
              >
                {t('landing:introduction.subtitle')}
              </Heading>
            </div>
            <div>
              <StyledTypography>
                <blockquote>
                  <FirstWordBold
                    str={t('landing:introduction.blockquote1')}
                    language={i18n.language}
                  />
                  <FirstWordBold
                    str={t('landing:introduction.blockquote2')}
                    language={i18n.language}
                  />
                  <FirstWordBold
                    str={t('landing:introduction.blockquote3')}
                    language={i18n.language}
                  />
                </blockquote>
              </StyledTypography>
            </div>
            <div>
              <Space
                full
                size={'large'}
                justify={'center'}
                lg={{
                  justify: 'flex-start',
                }}
              >
                <Link href={url.gacha_market}>
                  <Button type={'primary'}>{t('landing:PlayNow')}</Button>
                </Link>
                <LearnMore url={gitbookUrl.introduction[i18n.language]} />
              </Space>
            </div>
            <div>
              <Space direction={'vertical'} size={'xs'}>
                <Space
                  full
                  justify={'center'}
                  lg={{
                    justify: 'flex-start',
                  }}
                >
                  <Paragraph margin={0} align={'center'}>
                    {t('landing:introduction.para')}
                  </Paragraph>
                </Space>
                <Space
                  full
                  size={'large'}
                  justify={'center'}
                  lg={{
                    justify: 'flex-start',
                  }}
                >
                  <Link
                    target={'_blank'}
                    href={'https://www.akashicwallet.com/'}
                  >
                    <Image
                      width={150}
                      height={48}
                      src={
                        selectedThemeMode === themeMode.light
                          ? '/images/landing/aw-logo.svg'
                          : '/images/landing/aw-logo-white.svg'
                      }
                      alt={'Akashic Pay'}
                    />
                  </Link>
                </Space>
              </Space>
            </div>
          </Space>
        </Col>
        <Col span={24} order={1} md={{ span: 10, order: 2 }}>
          <PlaceHolder />
        </Col>
      </Row>
    </StyledSection>
  );
};

export default SectionIntroduction;
