import type { CSSProperties, FC, ReactNode } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { Col } from '../common/layout';

const StyledSection = styled.section(({ theme }) => ({
  width: '100%',
  maxWidth: 1200,
  position: 'relative',
  margin: `${theme.token.sizeLG}px auto`,
  padding: `0px ${theme.token.sizeLG}px`,
  [media.sm]: {
    padding: `${theme.token.sizeLG}px ${theme.token.sizeXL}px`,
  },
  [media.md]: {
    margin: `${theme.token.sizeLG}px auto`,
    padding: `${theme.token.sizeLG}px ${theme.token.sizeXXL}px`,
  },
  [media.lg]: {
    margin: '60px auto',
    padding: `${theme.token.sizeLG}px ${theme.token.sizeXXL}px`,
  },
  ['&:first-of-type']: {
    margin: `-${theme.token.sizeLG}px auto ${theme.token.sizeLG}px auto`,
    [media.sm]: {
      margin: `-${theme.token.sizeXS}px auto ${theme.token.sizeLG}px auto`,
    },
  },
}));

export const StyledTextCol = styled(Col)({
  ['h1, h2, h3, p']: {
    textAlign: 'center',
    [media.md]: {
      textAlign: 'left',
    },
  },
});

type SectionProps = {
  children: ReactNode;
  style?: CSSProperties;
};

const Section: FC<SectionProps> = ({ children, ...props }) => {
  return <StyledSection {...props}>{children}</StyledSection>;
};

export default Section;
