import type { GetStaticProps } from 'next';
import { LayoutMain } from '../components/layout/layout-main';
import { getServerSideTranslations } from '../libs/i18n';
import { commonConfig } from '../libs/i18n/common.config';
import SectionBorrowNft from '../components/landing/SectionBorrowNFT';
import SectionIntroduction from '../components/landing/SectionIntroduction';
import SectionStartingSteps from '../components/landing/SectionStartingSteps';
import SectionGachaRaffle from '../components/landing/SectionGachaRaffle';
import SectionNFTPurpose from '../components/landing/SectionNFTPurpose';
import SectionCandyLand from '../components/landing/SectionCandyLand';
import SectionQuestions from '../components/landing/SectionQuestions';
import SectionCTA from '../components/landing/SectionCTA';
import Head from 'next/head';

type Props = {
  /** Add props here */
};

export default function Index() {
  return (
    <LayoutMain fullWidth={true}>
      <Head>
        <title>Welcome to CandyPig!</title>
        <meta property="og:title" content="Welcome to CandyPig!" key="title" />
      </Head>
      <SectionIntroduction />
      <SectionGachaRaffle />
      <SectionStartingSteps />
      <SectionNFTPurpose />
      <SectionBorrowNft />
      <SectionCandyLand />
      <SectionQuestions />
      <SectionCTA />
    </LayoutMain>
  );
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new Error('locale is missing');
  }
  const { i18nNamespaces } = commonConfig;
  return {
    props: {
      ...(await getServerSideTranslations(
        locale,
        [...i18nNamespaces, 'landing'].slice()
      )),
    },
  };
};
