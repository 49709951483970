import type { FC, ReactNode } from 'react';
import { Card as AntdCard } from 'antd';
import { Heading, Paragraph } from '../typography';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../../libs/i18n/common.config';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { Col, Row, Space } from '../layout';

const StyledCard = styled(AntdCard)(({ theme }) => ({
  backgroundColor: theme.components.Hero.colorBgContainer,
  borderRadius: 32,
  ['&>.ant-card-body']: {
    padding: theme.token.sizeLG,
    [media.sm]: {
      padding: theme.token.sizeXXL,
    },
    [media.md]: {
      padding: `80px ${theme.token.sizeXXL}px`,
    },
    [media.lg]: {
      padding: 100,
    },
  },
}));
const ImgBox = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isLeftImage',
})<{ isLeftImage: boolean }>(({ isLeftImage }) => ({
  width: '100%',
  position: 'relative',
  height: 200,
  margin: 'auto',
  maxHeight: 400,
  ['img']: {
    objectFit: 'contain',
  },
  [media.sm_max]: {
    height: 200,
  },
  [media.sm]: {
    height: 260,
  },
  [media.md]: {
    position: 'absolute',
    top: 'calc(50% - 140px)',
    scale: '1.4',
    left: isLeftImage ? -20 : 20,
  },
  [media.lg]: {
    position: 'absolute',
    top: 'calc(50% - 140px)',
    scale: '1.4',
    left: isLeftImage ? -20 : 20,
  },
  [media.xl]: {
    position: 'absolute',
    scale: '1.6',
    left: isLeftImage ? -40 : 20,
  },
}));

type CardHeroProps = {
  imgPath: string;
  imgWidth: number;
  imgHeight: number;
  headingTKey: string;
  descTKeys: string[];
  imagePlacement?: 'left' | 'right';
  extra?: ReactNode;
};

const CardHero: FC<CardHeroProps> = ({
  imgPath,
  headingTKey,
  descTKeys,
  imagePlacement,
  extra,
  ...props
}) => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  const isLeftImage = imagePlacement === 'left';

  return (
    <StyledCard bordered={false} {...props}>
      <Row>
        <Col span={24} md={{ span: 12, order: isLeftImage ? 1 : 2 }}>
          <ImgBox isLeftImage={isLeftImage}>
            <Image
              fill
              src={imgPath}
              alt={t(headingTKey)}
              priority
              data-chromatic="ignore"
            />
          </ImgBox>
        </Col>
        <Col span={24} md={{ span: 12, order: isLeftImage ? 2 : 1 }}>
          <Space
            full
            direction={'vertical'}
            size={'xs'}
            justify={'center'}
            md={{
              justify: 'start',
            }}
          >
            <Heading
              align={'center'}
              md={{ align: 'start' }}
              level={2}
              color={'white'}
            >
              {t(headingTKey)}
            </Heading>
            <Space
              full
              direction={'vertical'}
              size={'xs'}
              align={'center'}
              lg={{ align: 'start' }}
            >
              <div>
                {descTKeys.map((x, idx) => (
                  <Paragraph
                    align={'center'}
                    md={{ align: 'start' }}
                    key={idx}
                    color="white"
                    margin={0}
                  >
                    {t(x)}
                  </Paragraph>
                ))}
              </div>
              {extra && <div>{extra}</div>}
            </Space>
          </Space>
        </Col>
      </Row>
    </StyledCard>
  );
};

export default CardHero;
