import styled from '@emotion/styled';
import type { FC } from 'react';
import React from 'react';

type Props = {
  str: string;
  language: string;
};

/**
 * Bold the first of a string word/character by the language
 * @param str the string
 * @param language language, currently Chinese(first character bold) or English(first word Bold)
 */

const MontserratSpanBold = styled('span')(({ theme }) => ({
  fontWeight: '600',
  fontSize: theme.token.fontSize,
}));

const MontserratSpan = styled('span')(({ theme }) => ({
  fontSize: theme.token.fontSize,
}));

const FirstWordBold: FC<Props> = ({ str, language }) => {
  if (language === 'zh-HK') {
    return (
      <div>
        <MontserratSpanBold>{str.substring(0, 2)}</MontserratSpanBold>
        <MontserratSpan>{str.substring(2)}</MontserratSpan>
      </div>
    );
  } else {
    return (
      <div>
        <MontserratSpanBold>
          {str.substring(0, str.indexOf(' '))}
        </MontserratSpanBold>
        <MontserratSpan>{str.substring(str.indexOf(' '))}</MontserratSpan>
      </div>
    );
  }
};

export default FirstWordBold;
