import { commonConfig } from '../../libs/i18n/common.config';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import styled from '@emotion/styled';
import { Link } from '../common/typography';
import Button from './Button';

type Props = {
  url: string;
};

const LinkWrap = styled(Link)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'flex-start',
  ['&.ant-typography']: {
    color: theme.token.colorPrimary,
  },
}));

const LearnMore = ({ url }: Props) => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <Button type={'link'}>
      <LinkWrap href={url} target={'_blank'}>
        <span>{t('LearnMore')}</span>
        &nbsp;
        <Image
          src={'/images/external-link.svg'}
          alt={'external-link'}
          width={20}
          height={20}
        />
      </LinkWrap>
    </Button>
  );
};

export default LearnMore;
