import React from 'react';
import styled from '@emotion/styled';
import Section from './Section';
// import { commonConfig } from '../../libs/i18n/common.config';
import Image from 'next/legacy/image';
import { media } from '@candypig-monorepo/types';
import { keyframes } from '@emotion/react';
import { useThemeMode } from '../../libs/hooks/useThemeMode';
import type { ThemeModeType } from '../../constant/themes';
import { themeMode } from '../../constant/themes';

const scaleUp = keyframes`
  0% {
    transform: scale(0.3);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const slideLR = keyframes`
  0% {
    transform: translateX(0) translateY(0) rotate(0);
  }
  100% {
    transform: translateX(-20px) translateY(-8px) rotate(-3deg);
  }
`;

const StyledBackgroundBox = styled.div({
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const StyledImageBox = styled.div({
  width: '100%',
  bottom: 0,
  position: 'absolute',
  [media.xxl]: {
    position: 'relative',
  },
});

export const StyledStar = styled.div({
  width: '100%',
  top: 0,
  position: 'absolute',
});

export const StyledBackgroundColor = styled.div<{
  selectedThemeMode: ThemeModeType;
}>((props) => ({
  background:
    props.selectedThemeMode === themeMode.light
      ? 'linear-gradient(180deg, #B1EFEE 0%, rgba(197, 244, 239, 0) 100%)'
      : 'transparent',
  position: 'relative',
  minHeight: 400,
  [media.md]: {
    minHeight: 560,
  },
  [media.lg]: {
    minHeight: 740,
  },
}));

export const StyledBigStar = styled.div({
  position: 'absolute',
  animation: `${scaleUp} 3800ms ease-in-out infinite 1600ms alternate both`,
  top: '60%',
  left: '30%',
  width: 50,
  [media.md]: {
    top: '52%',
    left: '32%',
  },
  [media.lg]: {
    top: '52%',
    left: '32%',
  },
  [media.xl]: {
    top: '42%',
    left: '33%',
  },
});

export const StyledBigStarTop = styled.div({
  position: 'absolute',
  animation: `${scaleUp} 2500ms ease-in-out infinite alternate both`,
  top: '54%',
  left: '23%',
  width: 50,
  [media.md]: {
    top: '32%',
    left: '26%',
  },
  [media.lg]: {
    top: '42%',
    left: '26%',
  },
  [media.xl]: {
    top: '32%',
    left: '26%',
  },
});

export const StyledBalloon = styled.div({
  position: 'absolute',
  animation: `${slideLR} 4800ms ease-in-out infinite alternate both`,
  top: '10%',
  right: '2%',
  width: 80,
  [media.sm]: {
    top: '10%',
    right: '2%',
    width: 110,
  },
  [media.md]: {
    top: '10%',
    right: '2%',
    width: 160,
  },
  [media.lg]: {
    width: 200,
    top: '10%',
    right: '2%',
  },
  [media.xl]: {
    width: 260,
    top: '12%',
    right: '2%',
  },
});

const StyledSection = styled(Section)(({ theme }) => ({
  ['&&']: {
    margin: `${theme.token.sizeLG}px auto ${theme.token.sizeLG}px auto`,
    [media.sm]: {
      margin: `${theme.token.sizeXS}px auto ${theme.token.sizeXXL}px auto`,
    },
  },
}));

const SectionCandyLand = () => {
  // const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { selectedThemeMode } = useThemeMode();

  return (
    <StyledBackgroundColor selectedThemeMode={selectedThemeMode}>
      <StyledImageBox>
        <Image
          src={'/images/landing/candyland/candyland-bg-3.png'}
          alt={''}
          width={3456}
          height={1536}
          layout={'responsive'}
        />
      </StyledImageBox>
      <StyledStar>
        <Image
          src={'/images/landing/candyland/stars.png'}
          alt={''}
          width={3038}
          height={1088}
          layout={'responsive'}
        />
      </StyledStar>
      <StyledBigStar>
        <Image
          src={'/images/landing/candyland/big-star.png'}
          alt={''}
          width={76}
          height={83}
          layout={'responsive'}
        />
      </StyledBigStar>
      <StyledBigStarTop>
        <Image
          src={'/images/landing/candyland/big-star-1.png'}
          alt={''}
          width={143}
          height={156}
          layout={'responsive'}
        />
      </StyledBigStarTop>
      <StyledBalloon>
        <Image
          src={'/images/landing/candyland/balloon-1.png'}
          alt={''}
          width={364}
          height={376}
          layout={'responsive'}
        />
      </StyledBalloon>
      <StyledBackgroundBox>
        <StyledSection>
          {/*<Row gutterSize={'xxl'} align={'middle'} justify={'center'}>*/}
          {/*  <Col*/}
          {/*    span={20}*/}
          {/*    offset={0}*/}
          {/*    sm={{ span: 16, offset: 4 }}*/}
          {/*    md={{ span: 12, offset: 7 }}*/}
          {/*  >*/}
          {/*    <Space direction={'vertical'} align={'center'} size={'xl'}>*/}
          {/*      <div>*/}
          {/*        <Heading align={'center'} color={'primary'} level={2}>*/}
          {/*          {t('landing:candyland.title')}*/}
          {/*        </Heading>*/}
          {/*        <Heading align={'center'} level={5}>*/}
          {/*          {t('landing:candyland.subtitle')}*/}
          {/*        </Heading>*/}
          {/*      </div>*/}
          {/*      <Link href={url.candyland}>*/}
          {/*        <Button type={'primary'}>*/}
          {/*          {t('landing:candyland.explore')}*/}
          {/*        </Button>*/}
          {/*      </Link>*/}
          {/*    </Space>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </StyledSection>
      </StyledBackgroundBox>
    </StyledBackgroundColor>
  );
};

export default SectionCandyLand;
